import React, { FC } from 'react';
import styled from 'styled-components';

import { Icon } from '@ovotech/element';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Text = styled.div`
  flex: 1;
  margin-left: ${({ theme }) => theme.core.space[4]};
  color: #555c6b;
`;

interface IconWithContentProps {
  iconName?: string;
}

export const IconWithContent: FC<IconWithContentProps> = ({
  iconName = 'cross',
  children,
}) => {
  return (
    <Wrapper>
      <Icon name={iconName} size={12} color={'red'} />
      <Text>{children}</Text>
    </Wrapper>
  );
};
