import { Form, useFormikContext } from 'formik';
import { FC, useEffect, useMemo } from 'react';
import styled, { css } from 'styled-components';

import { BasicPage, Basket, StyledCard } from '@components';
import { BasketNavigationButtons } from '@components/Basket/BasketNavigationButtons';
import { IconWithContent } from '@components/IconWithContent';
import { ScrollToFieldError } from '@components/ScrollToFieldError';
import { YesNoQuestion } from '@components/YesNoQuestion';
import { MixpanelEvents, RepairIssue } from '@entities/enums';
import { Quote } from '@entities/types';
import {
  Accordion,
  Divider,
  Heading3,
  Icon,
  Margin,
  Radio,
  RadioField,
  TextareaField,
} from '@ovotech/element';
import { useCustomMixpanel } from '@services/Mixpanel';
import { Repair, repairGroup, RepairProblem } from '@services/RepairService';
import { mqLargeUp, mqMediumUp } from '@utils/mediaQuery';
import { useMediaIs } from '@hooks/useMediaIs';
import { LoginCard } from '@components/LoginCard';
import {
  LOGGED_IN_REPAIR_COST,
  LOGGED_OUT_REPAIR_COST,
} from '@constants/homeRepair';
import { useAuth } from '@hooks/useAuth';

const Header = styled.div``;

const SubTitle = styled.div`
  font-size: 16px;
  line-height: 24px;
`;

const IssueOptionsSegment = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.core.space[4]};
`;

const IssueOptionContainer = styled.label(
  ({ defaultChecked, theme }) => css`
    position: relative;
    border-radius: 3px;
    color: ${defaultChecked
      ? theme.core.color.blue.dark
      : theme.core.color.brand.black};
    background: ${defaultChecked
      ? theme.core.color.blue.lightest
      : theme.core.color.neutral.lightest};
    border: 1px solid
      ${defaultChecked
        ? theme.core.color.blue.base
        : theme.semantic.border.differentiated};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0px 0px 12px 8px;
    cursor: pointer;
    flex: 45%;
    aspect-ratio: 1 / 1;

    ${mqLargeUp(`
      flex: 20%;
    `)}
  `
);

const IssueIconLabelWrapper = styled.div(
  ({ defaultChecked, theme }) => css`
    display: flex;
    flex-direction: column;
    margin-top: ${theme.core.space[4]};

    svg {
      color: ${defaultChecked
        ? theme.core.color.blue.dark
        : theme.core.color.green.light};
    }
  `
);

const IssueOptionLabel = styled.div`
  flex: 1;
  font-weight: 700;
  font-size: 14px;
  margin-top: 4px;

  ${mqMediumUp(`
    font-size: 18px;  
  `)}
`;

const RadioWrapper = styled.div`
  display: flex;
  align-self: flex-end;
`;

const ProblemTypesList = styled.div`
  label {
    width: 100%;
  }
`;

const AdditionalInfoSection = styled.div`
  margin-top: 14px;

  span {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    color: ${({ theme }) => theme.core.color.brand.black};
  }
`;

const ErrorHintMessage = styled.div`
  color: ${({ theme }) => theme.core.color.brand.black};
  font-size: 14px;
  line-height: 21px;
  padding: 12px 0;
`;

const YesNoQuestionWrapper = styled.div`
  margin-left: -4rem;
  margin-top: 2rem;
`;

const AccordionWrapper = styled.div`
  ${({ theme: { semantic } }) => `
    background-color: ${semantic.surface.base};
  `}
`;

const StyledRadioField = styled(RadioField)`
  & > div:has(> [value='Uncontrollable water leak'][checked]),
  & > div:has(> [value='Something else'][checked]),
  & > div:has(> [value='Full loss of power'][checked]) {
    ${({ theme: { core } }) => `
      background-color: ${core.color.blue.lightest};
      border-radius: ${core.radius.medium};
      padding: ${core.space[0]} ${core.space[2]};
    `}
  }
`;

interface Props {
  quote: Quote;

  onClickBack(): void;
}

const fieldsInOrder: (keyof Repair)[] = [
  'repairIssue',
  'problemType',
  'confirm',
  'notes',
];

export const RepairDetailsForm = ({ quote, onClickBack }: Props) => {
  const {
    handleChange,
    values,
    setFieldValue,
    isSubmitting,
    errors,
    setErrors,
  } = useFormikContext<Repair>();
  const { track } = useCustomMixpanel();
  const isMobile = useMediaIs('mobile', 'max');
  const { user, loading } = useAuth();

  useEffect(() => {
    if (errors.repairIssue) {
      track(MixpanelEvents.ERROR, {
        error: errors.repairIssue,
        element: 'RepairIssue',
      });
    }
  }, [errors.repairIssue, track]);

  // Available problem types for repair issue
  const problemTypes = useMemo(() => {
    if (!values.repairIssue) {
      return [];
    }
    return repairGroup.get(values.repairIssue) ?? [];
  }, [values.repairIssue]);

  // Selected repair problem
  const repairProblem: RepairProblem | undefined = useMemo(
    () => problemTypes.find((_) => _.problemType === values.problemType),
    [problemTypes, values.problemType]
  );
  const blockedSale = repairProblem?.blockedSale ?? true;
  const needAdditionalInfo = repairProblem?.needAdditionalInfo ?? false;

  const isLoggedIn = Boolean(user?.accountId && !loading);

  const repairCost = isLoggedIn
    ? LOGGED_IN_REPAIR_COST
    : LOGGED_OUT_REPAIR_COST;

  return (
    <Form data-testid={'repair-details-form'} onChange={() => setErrors({})}>
      <ScrollToFieldError<Repair> fieldsInOrder={fieldsInOrder} />
      <BasicPage.Content>
        <BasicPage.Left>
          <LoginCard />
          <Margin vertical={3} />
          <StyledCard>
            <Header>
              <Heading3>Tell us about your issue</Heading3>
              <Margin top={2} bottom={6}>
                <SubTitle>Please choose one of these options.</SubTitle>
              </Margin>
            </Header>
            <RadioField
              label={''}
              defaultValue={values.repairIssue}
              id={'repairIssue'}
              name={'issue'}
              error={errors.repairIssue}
              onChange={(e) => {
                handleChange(e);
                setFieldValue('problemType', undefined);
                const { value } = e.target as HTMLInputElement;
                track(MixpanelEvents.OPTION_SELECT, {
                  element: 'RepairType',
                  value,
                });
              }}
            >
              <IssueOptionsSegment>
                <IssueOption
                  repairIssue={RepairIssue.BOILER_AND_CENTRAL_HEATING}
                  id={'boiler'}
                  iconName={'boiler-service'}
                  defaultChecked={
                    values.repairIssue ===
                    RepairIssue.BOILER_AND_CENTRAL_HEATING
                  }
                />
                <IssueOption
                  repairIssue={RepairIssue.INTERNAL_DRAINS_AND_WASTE_PIPES}
                  id={'internal-drains-waste-pipes'}
                  iconName={'internal-drains'}
                  defaultChecked={
                    values.repairIssue ===
                    RepairIssue.INTERNAL_DRAINS_AND_WASTE_PIPES
                  }
                />
                <IssueOption
                  repairIssue={RepairIssue.PLUMBING}
                  id={'water'}
                  iconName={'plumbing'}
                  defaultChecked={values.repairIssue === RepairIssue.PLUMBING}
                />
                <IssueOption
                  repairIssue={RepairIssue.ELECTRICS}
                  id={'electronics'}
                  iconName={'electric'}
                  defaultChecked={values.repairIssue === RepairIssue.ELECTRICS}
                />
              </IssueOptionsSegment>
            </RadioField>
            {problemTypes.length > 0 && (
              <>
                <Margin top={6} bottom={6}>
                  <Divider type={'differentiated'} />
                </Margin>
                <Header>
                  <Heading3>Tell us a bit more</Heading3>
                  <Margin vertical={2} />
                  <SubTitle>
                    Please tell us about your problem by choosing an option from
                    the list below
                  </SubTitle>
                </Header>
                <ProblemTypesList>
                  <StyledRadioField
                    label={''}
                    id={'problemType'}
                    name={'problemType'}
                    error={errors.problemType}
                    onChange={(e) => {
                      handleChange(e);
                      const { value } = e.target as HTMLInputElement;
                      track(MixpanelEvents.OPTION_SELECT, {
                        element: 'ProblemType',
                        value,
                      });
                    }}
                  >
                    {problemTypes.map(
                      ({ problemType, dynamicMessage, question }, index) => (
                        <Radio
                          data-testid={problemType}
                          style={{ width: '100%' }}
                          id={`problemType-${index}`}
                          key={`${values.repairIssue}-${problemType}`}
                          label={problemType}
                          value={problemType}
                          onChange={handleChange}
                          defaultChecked={problemType === values.problemType}
                          hint={
                            values.problemType === problemType ? (
                              <ErrorHint
                                showYesNo={!blockedSale}
                                title={question}
                                error={dynamicMessage}
                              />
                            ) : null
                          }
                        />
                      )
                    )}
                  </StyledRadioField>
                </ProblemTypesList>
                <AdditionalInfoSection>
                  <TextareaField
                    onChange={handleChange}
                    defaultValue={values.notes}
                    name={'notes'}
                    fullWidth
                    label={`Additional fault  / property access information ${
                      needAdditionalInfo ? '*' : '(optional)'
                    }`}
                    id={'notes'}
                    hint={
                      errors.notes ? <ErrorHint error={errors.notes} /> : null
                    }
                    error={errors.notes ? ' ' : undefined}
                    maxLength={226}
                  />
                </AdditionalInfoSection>
                {values.repairIssue !== undefined && (
                  <>
                    <Margin top={6} bottom={6}>
                      <Divider type={'differentiated'} />
                    </Margin>
                    <AccordionWrapper>
                      <Accordion title={"What we won't repair"}>
                        <AccordionContent repairIssue={values.repairIssue} />
                      </Accordion>
                    </AccordionWrapper>
                  </>
                )}
              </>
            )}
            {!isMobile && (
              <>
                <Margin top={6} bottom={6}>
                  <Divider type={'differentiated'} />
                </Margin>
                <BasketNavigationButtons
                  isLoading={isSubmitting}
                  nextText="Go to personal details"
                  coverType={quote.coverType}
                  backButtonConfig={{
                    text: 'Back',
                    onClick: onClickBack,
                  }}
                />
              </>
            )}
          </StyledCard>
        </BasicPage.Left>
        <BasicPage.Right>
          <Basket
            quote={quote}
            isSubmitting={false}
            nextText={'Continue'}
            repairCost={repairCost}
          />
          {isMobile && (
            <>
              <Margin top={8} />
              <BasketNavigationButtons
                isLoading={isSubmitting}
                nextText="Go to personal details"
                coverType={quote.coverType}
                backButtonConfig={{
                  text: 'Back',
                  onClick: onClickBack,
                }}
              />
            </>
          )}
        </BasicPage.Right>
      </BasicPage.Content>
    </Form>
  );
};

interface IssueOptionProps {
  iconName: string;
  id: string;
  repairIssue: RepairIssue;
  defaultChecked: boolean;
}

const IssueOption = ({
  id,
  repairIssue,
  iconName,
  defaultChecked,
}: IssueOptionProps) => {
  return (
    <IssueOptionContainer
      defaultChecked={defaultChecked}
      htmlFor={id}
      data-testid={repairIssue}
    >
      <RadioWrapper>
        <Radio
          label={''}
          id={id}
          name={'repairIssue'}
          key={repairIssue}
          value={repairIssue}
          defaultChecked={defaultChecked}
        />
      </RadioWrapper>
      <IssueIconLabelWrapper defaultChecked={defaultChecked}>
        <Icon name={iconName} size={24} />
        <IssueOptionLabel>{repairIssue}</IssueOptionLabel>
      </IssueIconLabelWrapper>
    </IssueOptionContainer>
  );
};

interface AccordionContentProps {
  repairIssue: RepairIssue;
}

const AccordionContent: FC<AccordionContentProps> = ({ repairIssue }) => {
  switch (repairIssue) {
    case RepairIssue.BOILER_AND_CENTRAL_HEATING:
      return (
        <>
          <IconWithContent>Boilers beyond economical repair</IconWithContent>
          <IconWithContent>
            Cylinders, extended flue systems, and steel or iron pipes
          </IconWithContent>
          <IconWithContent>
            System design and installation faults
          </IconWithContent>
          <IconWithContent>
            Damage caused by corrosion, sludge, scale, or other debris
          </IconWithContent>
          <IconWithContent>Accidental damage</IconWithContent>
        </>
      );
    case RepairIssue.INTERNAL_DRAINS_AND_WASTE_PIPES:
      return (
        <>
          <IconWithContent>
            Faults caused by items unsuitable for disposal in drains, public
            drains and sewers
          </IconWithContent>
          <IconWithContent>Steel or iron pipes</IconWithContent>
          <IconWithContent>
            System design and installation faults
          </IconWithContent>
          <IconWithContent>
            Damage caused by corrosion, sludge, scale, or other debris
          </IconWithContent>
          <IconWithContent>Accidental damage</IconWithContent>
        </>
      );
    case RepairIssue.PLUMBING:
      return (
        <>
          <IconWithContent>
            Ceramic discs in taps or replace taps, faucets or shower hardware
          </IconWithContent>
          <IconWithContent>Leaking toilet pans</IconWithContent>
          <IconWithContent>Steel or iron pipes</IconWithContent>
          <IconWithContent>
            System design and installation faults
          </IconWithContent>
          <IconWithContent>
            Damage caused by corrosion, sludge, scale, or other debris
          </IconWithContent>
          <IconWithContent>Accidental damage</IconWithContent>
        </>
      );
    case RepairIssue.ELECTRICS:
      return (
        <>
          <IconWithContent>If a full system rewire is required</IconWithContent>
          <IconWithContent>
            Wiring that’s sheathed or cased in fabric
          </IconWithContent>
          <IconWithContent>
            Fluorescent light starters, decorative or low voltage lighting
          </IconWithContent>
          <IconWithContent>
            System design and installation faults
          </IconWithContent>
          <IconWithContent>Accidental damage</IconWithContent>
        </>
      );
  }
};

interface ErrorHintProps {
  title?: string;
  error: string | null;
  showYesNo?: boolean;
}

export const ErrorHint: FC<ErrorHintProps> = ({
  title = '',
  error,
  showYesNo = false,
}) => {
  const { values } = useFormikContext<Repair>();
  const { track } = useCustomMixpanel();
  useEffect(() => {
    if (error && !showYesNo) {
      track(MixpanelEvents.ERROR, {
        error,
      });
    }
  }, [error, showYesNo, track]);
  if (!error) {
    return null;
  }
  if (!showYesNo) {
    return (
      <ErrorHintMessage data-testid={'error-hint'}>{error}</ErrorHintMessage>
    );
  }
  return (
    <YesNoQuestionWrapper>
      <YesNoQuestion
        question={title}
        id={'confirm'}
        name={'confirm'}
        defaultValue={values.confirm || 'yes'}
        no={{
          hint: values.confirm === 'no' ? <ErrorHint error={error} /> : null,
        }}
      />
    </YesNoQuestionWrapper>
  );
};
